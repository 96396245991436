import * as React from "react";
import { CSSProperties, FC, useState } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading";
import { useInView } from "react-intersection-observer";
import { joinIgnoreEmpty } from "../../utilities";

interface IImage {
    data: IGatsbyImageData
    alt: string
    className?: string
    style?: CSSProperties
    objectFit?: 'cover' | 'contain' | 'fill'
}

const Image: FC<IImage> = ( { data, alt, className, style, objectFit = "cover" } ) => {
    const [ loaded, setLoaded ] = useState( false )
    const supportsLazyLoading = useNativeLazyLoading();
    const { ref, inView } = useInView( {
        triggerOnce: true,
        rootMargin : '200px 0px',
        skip       : supportsLazyLoading !== false,
    } );
    const cls = joinIgnoreEmpty( "w--100 relative ", className );
    return (
        <div className={ cls } ref={ ref } style={ style }>
            <AnimatePresence>
                { !inView && !loaded &&
                <motion.img src={ data.placeholder.fallback }
                            alt={ alt }
                            className={ "obj-fit--cover absolute inset--0 h--100 w--100" }
                            initial={ { opacity: 1 } }
                            animate={ { opacity: 1 } }
                            exit={ { opacity: 0 } }
                />
                }
            </AnimatePresence>
            { (inView || supportsLazyLoading) &&
            <picture>
                { data.images.sources.map( source =>
                    <source key={ source.type } type={ source.type } srcSet={ source.srcSet } sizes={ source.sizes }/>
                ) }
                <motion.img src={ data.images.fallback.src }
                            className={ `absolute inset--0 h--100 w--100 obj-fit--${ objectFit }` }
                            srcSet={ data.images.fallback.srcSet }
                            sizes={ data.images.fallback.sizes }
                            loading={ "lazy" }
                            alt={ alt }
                            onLoad={ () => setLoaded( true ) }
                            animate={ {
                                opacity: loaded ? 1 : 0
                            } }
                />
            </picture>
            }
        </div>
    )
}

export default Image
