import * as React from "react";
import { FC, memo, useState } from "react";
import { navigate } from "gatsby"
import { Popover } from '@meikidd/react-tiny-popover'
import { motion } from "framer-motion";
import { useLocalization } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"

interface ILanguageSwitcher {

}

const LanguageSwitcher: FC<ILanguageSwitcher> = memo( () => {
    const { locale } = useLocalization()
    const { pathname } = useLocation();
    const [ isOpen, setIsOpen ] = useState( false );

    const changeLanguage = ( l ) => {
        if (l === "en") {
            const path = pathname.replace( "/el", "" )
            navigate( path )
        } else {
            !pathname.includes( "el/" ) && navigate( "/el" + pathname )
        }
        setIsOpen( false )
    }
    const popOverContent =
        <motion.div className={ "lang-switcher__dropdown" } initial={ { y: 10 } } animate={ { y: 0 } }>
            <button className={ "lang-switcher__dropdown__item flex--center w--100 py--md px--lg cursor--pointer" } onClick={ () => changeLanguage( "el" ) }>
                <Flag className={ "mr--sm" } locale={ "el" }/>
                <div className={ "text--text" }>Ελληνικά</div>
            </button>
            <button className={ "lang-switcher__dropdown__item flex--center w--100 py--md px--lg cursor--pointer" } onClick={ () => changeLanguage( "en" ) }>
                <Flag className={ "mr--sm" } locale={ "en-US" }/>
                <div className={ "text--text" }>English</div>
            </button>
        </motion.div>

    return (
        <>
            <Popover
                isOpen={ isOpen }
                positions={ [ 'bottom' ] }
                align={ "end" }
                content={ popOverContent }
                containerClassName={ "z--7" }
                padding={ 10 }
                onClickOutside={ () => setIsOpen( false ) }
            >
                <button className={ "lang-switcher__button flex--center ml--lg cursor--pointer" } onClick={ () => setIsOpen( !isOpen ) } title={ `Select Language` }>
                    <Flag locale={ locale } height={ 32 }/>
                </button>
            </Popover>
        </>
    )
} )

export default LanguageSwitcher

interface IFlags {
    onClick?: () => void
    className?: string
    locale?: "en-US" | "el" | string
    height?: number
}

const Flag: FC<IFlags> = ( { onClick, className, locale, height = 24 } ) => {
    return (
        <svg height={ height } className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={ onClick }>
            { locale === "el" ? <>
                <circle className="st0" cx="256" cy="256" r="256" fill={ "#F0F0F0" }/>
                <path fill={ "#338AF3" } d="M256,189.2h247.2c-6.4-23.8-16.2-46.3-28.8-66.8H256V189.2z M96.6,456.3h318.7c23.4-18.6,43.4-41.2,59.1-66.8 H37.6C53.2,415.1,73.3,437.7,96.6,456.3z M89,61.9c-20.1,17.3-37.5,37.7-51.5,60.5H89V61.9z M256,256c0-26.1,0-47.9,0-66.8H155.8 V256H89v-66.8H8.8C3.1,210.5,0,232.9,0,256s3.1,45.5,8.8,66.8h494.4c5.7-21.3,8.8-43.7,8.8-66.8H256z M256,0 c-35.5,0-69.4,7.3-100.2,20.3v102.1H256c0-23.3,0-43.1,0-66.8h159.4C371.6,20.8,316.2,0,256,0z"/>
            </> : <>
                <path fill="#F0F0F0" d="M256,0c141.4,0,256,114.6,256,256S397.4,512,256,512S0,397.4,0,256S114.6,0,256,0z"/>
                <path fill="#0052B4" d="M52.9,100.1c-20.1,26.2-35.3,56.3-44.1,89.1H142L52.9,100.1z M503.2,189.2c-8.8-32.8-24-62.9-44.1-89.1 L370,189.2H503.2z M8.8,322.8c8.8,32.8,24,62.9,44.1,89.1l89.1-89.1H8.8L8.8,322.8z M411.9,52.9c-26.2-20.1-56.3-35.3-89.1-44.1V142 L411.9,52.9z M100.1,459.1c26.2,20.1,56.3,35.3,89.1,44.1V370L100.1,459.1z M189.2,8.8c-32.8,8.8-62.9,24-89.1,44.1l89.1,89.1V8.8z M322.8,503.2c32.8-8.8,62.9-24,89.1-44.1L322.8,370L322.8,503.2L322.8,503.2z M370,322.8l89.1,89.1c20.1-26.2,35.3-56.3,44.1-89.1 H370z"/>
                <path fill="#D80027" d="M509.8,222.6H289.4h0V2.2C278.5,0.7,267.3,0,256,0c-11.3,0-22.5,0.7-33.4,2.2v220.4v0H2.2 C0.7,233.5,0,244.7,0,256c0,11.3,0.7,22.5,2.2,33.4h220.4h0v220.4c10.9,1.4,22.1,2.2,33.4,2.2c11.3,0,22.5-0.7,33.4-2.2V289.4v0 h220.4c1.4-10.9,2.2-22.1,2.2-33.4C512,244.7,511.3,233.5,509.8,222.6z M322.8,322.8L322.8,322.8L437,437c5.3-5.3,10.3-10.7,15-16.4 l-97.8-97.8L322.8,322.8L322.8,322.8L322.8,322.8z M189.2,322.8L189.2,322.8L75,437c5.3,5.3,10.7,10.3,16.4,15l97.8-97.8 L189.2,322.8L189.2,322.8z M189.2,189.2L189.2,189.2L75,75c-5.3,5.3-10.3,10.7-15,16.4l97.8,97.8L189.2,189.2L189.2,189.2z M322.8,189.2L322.8,189.2L437,75c-5.3-5.3-10.7-10.3-16.4-15l-97.8,97.8V189.2z"/>
            </> }
        </svg>
    )
}
