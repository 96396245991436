import * as React from "react";
import { FC } from "react";
// import SideMenu from "../SideMenu/SideMenu";
import { motion } from "framer-motion"
import Header from "../Header/Header";
import { joinIgnoreEmpty } from "../../utilities";

interface ILayout {
    staggerChildren?: number,
    className?: string
    noPadding?: true
    noContentPadding?: true
    noContainer?: true
}

const Layout: FC<ILayout> = ( { children, staggerChildren = .15, className, noPadding, noContainer, noContentPadding } ) => {
    const variants = {
        init: {
            opacity: 0,
        },
        in  : {
            opacity   : 1,
            transition: {
                type           : "tween",
                ease           : "easeInOut",
                duration       : .3,
                staggerChildren: staggerChildren,
                when           : "beforeChildren",
            }
        },
        out : {
            opacity   : 0,
            transition: {
                type    : "tween",
                ease    : "easeInOut",
                duration: .3,
            }
        }
    }
    const cls = joinIgnoreEmpty( noContentPadding ? "" : "p--md", noContainer ? "" : "container", "min-h--main", className )
    return (
        <>
            {/*<SideMenu/>*/}
            <Header/>
            <motion.main className={ cls } variants={ variants } initial={ "init" } animate={ "in" } exit={ "out" }>
                { children }
            </motion.main>
            <footer className={ "bg--bg" }>
                <div className={ "container lg:flex p--xxl text--textDimmed" }>
                    <div className={ "lg:col--4 p--xl" }>
                        <h3 className={ "mb--md text--darkGray h2" }>Menu</h3>
                    </div>
                    <div className={ "lg:col--4 p--xl" }>
                        <h3 className={ "mb--md text--darkGray h2" }>Contact</h3>
                        <p>Industrial Area Aradippou</p>
                        <p>Site 33, Larnaka, CY 7104</p>
                        <p>+357 962 432 47</p>
                        <p>contact@modplus.cy</p>
                    </div>
                    <div className={ "lg:col--4 p--xl" }>
                        <h3 className={ "mb--md text--darkGray h2" }>Social Media</h3>
                        <SocialMedia/>
                    </div>
                </div>
                <div className={ "bg--body p--lg flex--center" }>
                    <div>© { new Date().getFullYear() } <span className={ "font--bold" }>MODplus P.C.</span></div>
                    <div className={ "ml--auto" }>Made by: <a href="https://emmbrand.com" target="_blank" rel="noopener noreferrer">emmbrand</a></div>
                </div>
            </footer>
        </>
    )
}

export default Layout

const SocialMedia = () => (
    <div className="social-media flex--center mb--xxl pt--sm">
        <a href="https://www.facebook.com/modplus/" target="_blank" className="mr--md inline-flex">
            <svg fill="#4E598F" height="32" aria-labelledby="facebook" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title id="facebook">Facebook</title>
                <circle fill="white" cx="256" cy="256" r="242"/>
                <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M402.5,363 c0,21.8-17.7,39.5-39.5,39.5h-62.8l0.2-104.7h36.2c3.3,0,6-2.4,6.4-5.7l4-33.8c0.5-3.8-2.5-7.2-6.4-7.2h-40.4V223 c0-12.8,3.6-21.5,21.9-21.5l17,0c3.5,0,6.4-2.9,6.4-6.4v-28.5c0-3.5-2.9-6.4-6.4-6.4h-27.7c-33.8,0-56.9,20.6-56.9,58.5v32.6h-27.2 c-3.6,0-6.4,2.9-6.4,6.4l0.1,33.8c0,3.5,2.9,6.4,6.4,6.4h26.8v0h0.4l-0.2,101.6l0,3.1H149c-21.8,0-39.5-17.7-39.5-39.5V149 c0-21.8,17.7-39.5,39.5-39.5H363c21.8,0,39.5,17.7,39.5,39.5V363z"/>
            </svg>
        </a>
        <a href="https://www.instagram.com/modplusgreece/" target="_blank" className="mr--md inline-flex">
            <svg fill="#D62976" height="32" aria-labelledby="instagram" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title id="instagram">Instagram</title>
                <circle fill="white" cx="256" cy="256" r="242"/>
                <path d="M315.2,139H196.8c-31.9,0-57.8,25.9-57.8,57.8v118.5c0,31.9,25.9,57.8,57.8,57.8h118.5 c31.9,0,57.8-25.9,57.8-57.8V196.8C373,164.8,347.2,139,315.2,139z M256,331.8c-41.8,0-75.8-34-75.8-75.8c0-41.8,34-75.8,75.8-75.8 s75.8,34,75.8,75.8C331.8,297.8,297.8,331.8,256,331.8z M302.3,256c0,25.6-20.7,46.3-46.3,46.3c-25.6,0-46.3-20.7-46.3-46.3 c0-25.6,20.7-46.3,46.3-46.3C281.6,209.7,302.3,230.4,302.3,256z M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256 S397.4,0,256,0z M402.5,315.2c0,48.1-39.2,87.3-87.3,87.3H196.8c-48.1,0-87.3-39.2-87.3-87.3V196.8c0-48.1,39.2-87.3,87.3-87.3 h118.5c48.1,0,87.3,39.2,87.3,87.3V315.2z"/>
            </svg>
        </a>
        <a href="https://www.linkedin.com/company/modplus/" target="_blank" className="mr--md inline-flex">
            <svg fill="#4376B1" height="32" aria-labelledby="linkedin" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title id="linkedin">Linkedin</title>
                <circle fill="white" cx="256" cy="256" r="242"/>
                <path d="M200.2,224.9v137.7h0c0,4.3-3.5,7.7-7.7,7.7h-32.8c-4.3,0-7.7-3.5-7.7-7.7V224.9c0-4.3,3.5-7.7,7.7-7.7h32.8 C196.7,217.1,200.2,220.6,200.2,224.9z M176,141.8c-17.2,0-31.2,14-31.2,31.2c0,17.2,14,31.2,31.2,31.2c17.2,0,31.2-14,31.2-31.2 C207.2,155.7,193.3,141.8,176,141.8z M512,256c0,141.4-114.6,256-256,256S0,397.4,0,256S114.6,0,256,0S512,114.6,512,256z M402.5,136c0-14.6-11.9-26.5-26.5-26.5H136c-14.6,0-26.5,11.9-26.5,26.5v240c0,14.6,11.9,26.5,26.5,26.5h240 c14.6,0,26.5-11.9,26.5-26.5V136z M315.7,214.8c-25.5,0-37.4,9.3-45.5,21.4v-12c0-3.9-3.2-7.1-7.1-7.1H229c-3.9,0-7.1,3.2-7.1,7.1 v138.9c0,3.9,3.2,7.1,7.1,7.1h34.1c3.9,0,7.1-3.2,7.1-7.1v-74.5c0.9-10,5.3-32.3,27-32.3c28,0,25.2,32.6,25.2,42.2v64.6 c0,3.9,3.2,7.1,7.1,7.1h35.2c3.9,0,7.1-3.2,7.1-7.1v0h0v-66.6C371.8,267.5,372.1,214.8,315.7,214.8z"/>
            </svg>
        </a>
        <a href="https://pinterest.com/modplus0242" target="_blank" className="mr--md inline-flex">
            <svg fill="#862328" height="32" aria-labelledby="pinterest" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title id="pinterest">Pinterest</title>
                <circle fill="white" cx="256" cy="256" r="242"/>
                <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M402.5,260.3 c-2.2,76.9-68.2,141.5-145.1,142.2c-13,0.1-25.5-1.4-37.5-4.5c-2.6-0.7-3.9-3.6-2.6-6c4.9-9,9.7-19.2,12-28.1 c1.3-4.9,5.2-20,7.7-29.3c0.8-2.9,4.4-3.9,6.5-1.8c7,6.9,18.8,12,31.2,12c47,0,80.8-43.2,80.8-96.9c0-51.5-42-90-96-90 c-67.2,0-102.9,45.1-102.9,94.3c0,22.8,12.2,51.3,31.6,60.3c2.9,1.4,4.5,0.8,5.2-2.1c0.5-2.2,3.1-12.7,4.3-17.7 c0.4-1.6,0.2-2.9-1.1-4.5c-6.4-7.8-11.6-22.2-11.6-35.5c0-34.4,26-67.6,70.3-67.6c38.3,0,65.1,26.1,65.1,63.4 c0,42.1-21.3,71.3-49,71.3c-15.3,0-26.7-12.6-23.1-28.1c4.4-18.5,12.9-38.5,12.9-51.9c0-12-6.4-21.9-19.7-21.9 c-15.6,0-28.2,16.2-28.2,37.8c0,4.3,0.4,8.1,1.1,11.4c1.9,10,1.6,20.2-0.7,30.1c-4.8,20.3-12.1,51.1-13.9,59.1 c-1.9,8.1-2.2,18.2-1.8,27.6c0.1,3.1-3.1,5.2-5.9,3.9c-48.5-23.6-82.1-73.2-82.5-130.7c-0.6-80.2,64.1-146.5,144.3-147.7 C337.2,108.3,404.9,176.7,402.5,260.3z"/>
            </svg>
        </a>
        <a href="https://www.youtube.com/channel/UC_34qQfD1qfT0d5Gegrr1eA" target="_blank" className="inline-flex">
            <svg fill="#D22215" height="32" aria-labelledby="youtube" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <title id="youtube">YouTube</title>
                <circle fill="white" cx="256" cy="256" r="242"/>
                <path d="M300.5,260.2l-80.1,38.2c-2.1,1-4.6-0.5-4.6-2.9v-78.8c0-2.4,2.5-4,4.7-2.9l80.1,40.6 C302.9,255.6,302.9,259,300.5,260.2z M512,256c0,141.4-114.6,256-256,256S0,397.4,0,256S114.6,0,256,0S512,114.6,512,256z M402.5,213.2c0-33.6-27.2-60.9-60.9-60.9H170.4c-33.6,0-60.9,27.2-60.9,60.9v85.6c0,33.6,27.2,60.9,60.9,60.9h171.3 c33.6,0,60.9-27.2,60.9-60.9V213.2z"/>
            </svg>
        </a>
    </div>
)
