import * as React from "react";
import { FC, useEffect } from "react";
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";
import t from "../i18n";
import { sendGoogleAnalyticsView, useCookieContext } from "@emmbrand/gatsby-plugin-analytics-and-consent";

interface IHeadMeta {
    title?: string
    description?: string
    image?: string
    type?: "website"
}

const HeadMeta: FC<IHeadMeta> = ( { title, description, image, type, children } ) => {
    const { cookiesConsent } = useCookieContext();
    const { ogImage, site: { siteMetadata } } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        description
                    }
                }
                ogImage: file(relativePath: {eq: "og-image.jpg"}) {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
            }
        `
    )
    const domain = siteMetadata.siteUrl;
    const location = useLocation()
    const url = domain + location.pathname;
    const pageTitle = `${ title } | ${ siteMetadata.title }`
    const pageDescription = description ?? t( siteMetadata.description );
    const imageWithFallback = image ? domain + image : `${ domain }${ ogImage.childImageSharp.original.src }`

    useEffect( () => {
        if (excludePages.some( path => location.pathname === path || location.pathname === `/el${ path }` )) return;
        sendGoogleAnalyticsView( {
            debugDev   : true,
            consent    : cookiesConsent === "true",
            eventParams: {
                page_title   : pageTitle,
                page_location: url,
                page_path    : location.pathname,
                send_to      : process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID
            }
        } )

    }, [ cookiesConsent ] )

    return (
        <Helmet>
            <link rel="icon" href={ process.env.domain + "/favicon.ico" }/>
            <title>{ pageTitle }</title>
            <meta name="title" content={ pageTitle }/>
            <meta name="description" content={ pageDescription }/>
            <meta property="og:type" content={ type ?? "website" }/>
            <meta property="og:url" content={ url }/>
            <meta property="og:title" content={ pageTitle }/>
            <meta property="og:description" content={ pageDescription }/>
            <meta property="og:image" content={ imageWithFallback }/>
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={ url }/>
            <meta property="twitter:title" content={ pageTitle }/>
            <meta property="twitter:description" content={ pageDescription }/>
            <meta property="twitter:image" content={ imageWithFallback }/>
            { children }
        </Helmet>
    )
}

export default HeadMeta

const excludePages = [ "/terms-and-conditions", "/privacy-policy", "/cookies-policy" ]
